<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'holidays' }">Holidays</router-link>
        </li>
        <li class="breadcrumb-item active">Add</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
        <div class="col-5">
          <h2 class="title2">Add Holiday</h2>
        </div>
        <div class="col text-end">
          <router-link
            :to="{ name: 'holidays' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div v-if="this.$store.state.loader">
        <loader
          object="#f74b3d"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="80"
          disableScrolling="false"
          name="spinning"
        ></loader>
      </div>
      <form v-else @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-lg-6 mb-3">
            <div class="card">
              <div class="card-header"></div>
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label">Name</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="$v.form.name.$model"
                      :validator="$v.form.name"
                    />
                  </div>
                  <p class="error" v-if="$v.form.name.$error == true">
                    Holiday name is required
                  </p>
                </div>
                <div class="mb-3">
                  <label class="form-label">Date</label>
                  <datepicker
                    :typeable="true"
                    placeholder="Select Date"
                    class="form-control"
                    v-model="$v.form.date.$model"
                    :validator="$v.form.date"
                    :format="customFormatter"
                    :highlighted="highlighted"
                  ></datepicker>
                  <span class="error" v-if="$v.form.date.$error == true"
                    >Date is required</span
                  >
                </div>
                <!--<div class="mb-3">
                  <label class="form-label">Holiday Type</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.type.$model"
                    :validator="$v.form.type"
                    :options="typeOptions"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.type.$error == true"
                    >Please select holiday type
                  </span>
                </div>-->
                <!-- <div class="mb-3">
                  <label class="form-label">Status</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.status.$model"
                    :validator="$v.form.status"
                    :options="statusOptions"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.status.$error == true"
                    >Please select status
                  </span>
                </div> -->
                <div class="text-right">
                  <button type="submit" class="btn btn-outline-primary space">
                    Save
                  </button>
                  <router-link
                    :to="{ name: 'holidays' }"
                    class="btn btn-outline-primary"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
export default {
  components: { Datepicker },
  data() {
    return {
      form: {
        name: null,
        date: null,
        type: null,
        status: null,
      },

      typeOptions: [
        {
          value: "Restricted",
          text: "Restricted",
        },
        {
          value: "Gazetted",
          text: "Gazetted",
        },
      ],
      statusOptions: [
        {
          value: "Published",
          text: "Published",
        },
        {
          value: "Archived",
          text: "Archived",
        },
      ],
      highlighted: { dates: [new Date("yyyy-MM-dd")] },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      // type: {
      //   required,
      // },
      date: {
        required,
      },
      // status: {
      //   required
      // }
    },
  },
  created() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadEmployees");
  },
  mounted() {
    console.log("users ", this.employees);
  },
  methods: {
    customFormatter(date) {
      let formateedDate = moment(date).format("yyyy-MM-DD");
      this.form.date = formateedDate;
      return formateedDate;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      axios
        .post("/holidays/add", this.form)
        .catch(({ response }) => {
          this.flashMessage.error({
            message: response.data.error,
            time: 3000,
          });
        })
        .then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "holidays" });
          }
        });
    },
    onChangeFileUpload() {
      this.form.image = this.$refs.file.files[0];
    },
  },
};
</script>
